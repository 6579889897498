import * as React from "react";
import PropTypes from "prop-types";
import './tag.scss';



const Tag  = ({ title }) =>{
  return (
    <div className="tag">
      {title}
    </div>
  );
};
Tag.propTypes = {
  title: PropTypes.string,
};

export default Tag;

