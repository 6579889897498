import * as React from "react";
import './more-button.scss';
import {Link} from "gatsby";

const MoreButton = () => {
  return (
    <button className="more-button">
      more
    </button>
  );
}


export default MoreButton;

