import * as React from "react";
import './news-item.scss'
import URL from "../elements/url";
import Date from "../elements/date";
import Tag from "../elements/tag";
import {FC, ReactNode} from "react";

interface NewsItemProps {
    text: ReactNode;
    url: string;
    date: string;
    label?: string;
}

const NewsItem: FC<NewsItemProps> = ({ text, url, date, label}: NewsItemProps) => {
  return (
    <div className="news-item">
      <div className="news-item__head-information">
        <Tag title="NEWS"/>
        <div className="news-item__date"><Date date={date}/></div>
      </div>
      <div className="news-item__text" lang="en">
        {text}&nbsp;
      </div>
      { url && <URL url={url} label={label}/>}
    </div>
  );
};

export default NewsItem;

