import * as React from "react";
import './about.scss'
import {FC} from "react";
import Image from "./image";
import {graphql, useStaticQuery} from "gatsby";

const About: FC = () => {
    const aboutQueryResult = useStaticQuery(graphql`
    query About {
      allMarkdownRemark(filter: {fields: {slug: {eq: "/single-pages/about/"}}}) {
        nodes {
          html
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 1500)
              }
            }
          }
        }
      }
    }
 `)
    const about = aboutQueryResult.allMarkdownRemark.nodes[0];

  return (
    <div className="grid about">
      <div className="about__text">
        <div className="about__title">
          {about.title}
        </div>
        <div dangerouslySetInnerHTML={{__html: about.html}}/>
      </div>
      <div className="about__green"></div>
      <div className="about__video-or-image">
        <video className="about__video-or-image" poster="/field.jpg" autoPlay muted loop preload="auto" playsInline>
          <source src="/field.mp4" type="video/mp4" />
          <source src="/field.ogg" type="video/ogg" />
          <source src="/field.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default About;

