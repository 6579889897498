import {Link} from "gatsby";
import * as React from "react";
import PropTypes from "prop-types";
import './button.scss';

interface ButtonProps {

  action?: string;
  actionPath?: string;
}

const Button = ({ action, actionPath}: ButtonProps) => {
  return (
    <div className="button">
<Link className="button__action-toucharea" to={actionPath}>
  <div className="button__action-border">
    {action}
  </div>
</Link>
    </div>
  );
};


export default Button;
