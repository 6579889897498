import {
    Article,
    ArticleOrNews,
    ArticleQueryResult,
    News, Publication,
    TEMPLATE_KEY_NEWS,
    TEMPLATE_KEY_PUBLICATIONS,
    TEMPLATE_KEY_RESEARCH_ARTICLE,
    TEMPLATE_KEY_THOUGHT_ARTICLE
} from "./types";
import Transition from "./pages/transition";

export function getThoughts(page: ArticleQueryResult): Article[] {
    return filterArticles(page, a => a.frontmatter.templateKey === TEMPLATE_KEY_THOUGHT_ARTICLE);
}

export function getThoughtsAndNews(page: ArticleQueryResult): ArticleOrNews[] {
    return filterArticles(page, a => a.frontmatter.templateKey === TEMPLATE_KEY_THOUGHT_ARTICLE || a.frontmatter.templateKey === TEMPLATE_KEY_NEWS);
}

export function getNews(page: ArticleQueryResult): News[] {
    return filterArticles(page,a => a.frontmatter.templateKey === TEMPLATE_KEY_NEWS);
}

export function getResearch(page: ArticleQueryResult): Article[] {
    return filterArticles(page,a => a.frontmatter.templateKey === TEMPLATE_KEY_RESEARCH_ARTICLE);
}

export function getPublications(page: ArticleQueryResult): Publication[] {
    return filterArticles(page,a => a.frontmatter.templateKey === TEMPLATE_KEY_PUBLICATIONS);
}

export function getTransition(page: ArticleQueryResult): Article {
    return filterArticles(page,a => a.fields.slug === "/single-pages/transition/")[0] as Article;
}

function filterArticles<T>(page: ArticleQueryResult, keep: (n: ArticleOrNews) => boolean): T[] {
    return page.data.articles.nodes.filter(keep) as unknown as T[];
}



