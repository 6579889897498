import * as React from "react";
import './news-layout.scss';
import {ReactNode} from "react";

interface NewsLayoutProps {
    children: ReactNode;
}

const NewsLayout = ({ children }: NewsLayoutProps) => {
  return (
    <div className="news-layout">
      <div className="news-layout__container">
        {children}
      </div>
    </div>
  );
};

export default NewsLayout;

