import * as React from "react";
import PropTypes from "prop-types";
import './page-header.scss'
import { Link } from "gatsby";
import Navigation from "./navigation";
import Page from "./page";
import MobileNavigation from "./mobile-navigation";

const PageHeader = ({ title, subline }) => {
  return (
    <div className="page-header grid">
      <div className="page-header__container">
        <Navigation activePage="home"/>
        <MobileNavigation activePage="home"/>
      <h1 className="page-header__title" >
        {title}
      </h1>
      <h2 className="page-header__subline">{subline}</h2>
      </div>
    </div>
  );
};

PageHeader.propTypes = {

  title: PropTypes.string,
  subline: PropTypes.string,


};

export default PageHeader;

