import * as React from "react"
import {FC} from "react"
import '../neat-basics/index.scss'
import "./theming.scss"
import "./index.scss"
import "../styles/styles.scss"
import PageHeader from "../modules/page-header"
import Page from "../modules/page"
import Teaser, {Type} from "../modules/teaser"
import Section from "../modules/section"
import SectionTitle from "../elements/section-title"
import NewsLayout from "../modules/news-layout";
import {getNews, getPublications, getResearch, getThoughts, getTransition} from "../util";
import {ArticleQueryResult} from "../types";
import {graphql} from "gatsby";
import NewsItem from "../modules/news-item";
import About from "../modules/about";
import PublicationItem from "../modules/publication-item";
import Footer from "../modules/footer";
import Button from "../elements/button";
import Main from "../modules/main";

const IndexPage: FC<ArticleQueryResult> = (page: ArticleQueryResult) => {
    const thoughts = getThoughts(page);
    const news = getNews(page);
    const research = getResearch(page);
    const publications = getPublications(page).slice(0, 3);
    const firstThought = thoughts.shift();
    const secondThought = thoughts.shift();
    const firstNews = news.shift();
    const firstResearch = research.shift();
    const transition = getTransition(page)

    return (<>
            <Main title="Thinking about the world - and a just future">
                <Page>
                    <div className="index">
                        <PageHeader title="Tijo Salverda" subline="Thinking about the world - and a just future"/>
                        <div className="colored-wrapper">
                            <Section>
                                <SectionTitle title="Recent news and thoughts" action="show all"
                                              actionPath="/news_and_thoughts">
                                    <Button action="show all" actionPath="/news_and_thoughts"/>
                                </SectionTitle>
                                {firstThought &&
                                    <Teaser tag="RECENT THOUGHT" actionPath={firstThought.fields.slug}
                                            image={firstThought.frontmatter.image} type={Type.LARGE}
                                            date={firstThought.frontmatter.date} title={firstThought.frontmatter.title}
                                            text={firstThought.excerpt}/>
                                }
                                <NewsLayout>
                                    {firstNews &&
                                        <NewsItem
                                            text={firstNews.frontmatter.text}
                                            date={firstNews.frontmatter.date}
                                            url={firstNews.frontmatter.url}
                                            label={firstNews.frontmatter.linkLabel}
                                        />
                                    }
                                    {secondThought &&
                                        <Teaser tag="THOUGHT" actionPath={secondThought.fields.slug}
                                                image={secondThought.frontmatter.image} type={Type.SMALL}
                                                date={secondThought.frontmatter.date}
                                                title={secondThought.frontmatter.title} text={secondThought.excerpt}/>
                                    }
                                </NewsLayout>
                            </Section>
                        </div>
                        <Section>
                            <SectionTitle title="Transition" action="more" actionPath="/transition">
                                {/*<Button action="more" actionPath="/transition"/>*/}
                            </SectionTitle>
                            {transition &&
                                <Teaser actionPath={"/transition"} image={transition.frontmatter.image}
                                        type={Type.TRANSITION}
                                        title={transition.frontmatter.title}
                                        text={transition.excerpt} defaultImage={true}
                                />
                            }
                        </Section>
                        <Section grey={true}>
                            <SectionTitle title="Recent Research" action="show all" actionPath="/research">
                                <Button action="show all" actionPath="/research"/>
                            </SectionTitle>
                            {firstResearch &&
                                <Teaser actionPath={firstResearch.fields.slug} image={firstResearch.frontmatter.image}
                                        type={Type.LARGE}
                                        date={firstResearch.frontmatter.date} title={firstResearch.frontmatter.title}
                                        text={firstResearch.excerpt} defaultImage={true}/>
                            }
                        </Section>
                        <About/>
                        <Section>
                            <SectionTitle title="Recent Publications">
                                <Button action="show all" actionPath="/publications"/>
                            </SectionTitle>

                            {publications.map(p =>
                                <PublicationItem {...p} key={p.frontmatter.date} showType={true}/>
                            )}
                        </Section>
                    </div>
                </Page>

                <Footer/>
            </Main>
        </>
    )
}

export default IndexPage

export const pageQuery = graphql`
  query AllArticlesIndexPage {
    articles: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        ...AllArticlesAndNews
      }
    }
  }
`

export const allArticlesAndNewsFragment = graphql`
fragment AllArticlesAndNews on MarkdownRemark {
        id
        html
        excerpt(format: PLAIN, pruneLength: 5000)
        fields {
          slug
        }
        frontmatter {
          date
          title
          text
          templateKey
          url
          linkLabel
          image {
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
          category
          type
      }
   }`

