import * as React from "react";
import './teaser.scss'
import Image from "../elements/image";
import Date from "../elements/date";
import MoreButton from "../elements/more-button";
import {Link} from "gatsby";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {CMSFile} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import Tag from "../elements/tag";
import classNames from "classnames";

interface TeaserProps {
    date?: string;
    title: string;
    text: string;
    type: Type;
    image?: FileNode;
    actionPath: string
    defaultImage?: boolean
    setLastImageUpdateTimestamp?: (time: number) => void;
    tag?: string;
    maxTextLength?: number
}

export enum Type {
    LARGE = "large-image",
    TRANSITION = "transition",
    SMALL = "small-image",
}

class RelationValue {
    private _text: number;
    private _title: number;

    constructor(text: number, title: number) {
        this._text = text;
        this._title = title;
    }

    get text(): number {
        return this._text;
    }

    get title(): number {
        return this._title;
    }
}

function Relation(params: { text: number; title: number }) {
    return new RelationValue(params.text, params.title);
}

const TITLE_TEXT_RELATION_LARGE_IMAGE = [Relation({title: 30, text: 240}), Relation({
    title: 40,
    text: 175
}), Relation({title: 75, text: 130})]
const TITLE_TEXT_RELATION_LARGE_NO_IMAGE = [Relation({title: 100, text: 300}), Relation({
    title: 200,
    text: 300
}), Relation({title: 300, text: 300})]
const TITLE_TEXT_RELATION_SMALL_IMAGE = [Relation({title: 40, text: 130}), Relation({
    title: 200,
    text: 300
}), Relation({title: 300, text: 300})]
const TITLE_TEXT_RELATION_SMALL_NO_IMAGE = [Relation({title: 40, text: 280}), Relation({
    title: 80,
    text: 210
}), Relation({title: 100, text: 125})]

const Teaser = ({
                    title,
                    text,
                    image,
                    date,
                    type,
                    actionPath,
                    defaultImage,
                    setLastImageUpdateTimestamp,
                    tag, maxTextLength
                }: TeaserProps) => {
    function teaserType(type: Type | undefined): string {
        if (!image && !defaultImage) {
            return type === Type.LARGE ? "large-no-image" : "no-image";
        }

        return type || "no-image";
    }

    function findRelation(length: number, relations: RelationValue[]) {
        const smallestMatchingRelation = relations.find(r => r.title > length);

        if (smallestMatchingRelation) {
            return smallestMatchingRelation;
        } else {
            return relations[relations.length - 1];
        }
    }

    function relation(): RelationValue {
        if (image) {
            if (type === Type.LARGE) {
                return findRelation(title.length, TITLE_TEXT_RELATION_LARGE_IMAGE);
            } else {
                return findRelation(title.length, TITLE_TEXT_RELATION_SMALL_IMAGE);
            }
        } else {
            if (type === Type.LARGE) {
                return findRelation(title.length, TITLE_TEXT_RELATION_LARGE_NO_IMAGE);
            } else {
                return findRelation(title.length, TITLE_TEXT_RELATION_SMALL_NO_IMAGE);
            }
        }
    }

    function textLength(): number {
        return relation().text;
    }

    function titleLength(): number {
        return relation().title
    }

    function shorten(string: string, length: number) {
        const suffix = string.length > length ? "..." : "";
        return string.substr(0, length - 1) + suffix;
    }

    return (
        <Link className={classNames(`teaser teaser--${teaserType(type)}`, {'teaser--no-date': !date})} to={actionPath}>
            {(tag || date) && <div className="teaser__head-information">
                {tag && <Tag title={tag}/>}
                {date && <div className="teaser__date"><Date date={date}/></div>}
            </div>}
            {image &&
                <div className="teaser__image">
                    <Image type="cover" img={image} setLastImageUpdateTimestamp={setLastImageUpdateTimestamp}/>
                </div>
            }
            {defaultImage && !image &&
                <div className="teaser__image">
                    <StaticImage alt="" src="../images/teaser-fallback.jpg"/>
                </div>
            }

            <div className="border"/>

            {title && <div className="teaser__title" lang="en">{shorten(title, titleLength())}</div>}

            <div className="teaser__text">
                {shorten(text,maxTextLength ||  textLength())}{''}<MoreButton/>
            </div>
        </Link>
    );
}

export default Teaser;

