import * as React from "react";
import './image.scss'
import Img, {FluidObject} from "gatsby-image"
import {GatsbyImage, getImage, IGatsbyImageData} from "gatsby-plugin-image";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";

interface ImageProps {
  img: FileNode;
  type: string;
  setLastImageUpdateTimestamp?: (time: number) => void;
}

const Image = ({img, type, setLastImageUpdateTimestamp}: ImageProps) => {
  return <GatsbyImage onLoad={() => setLastImageUpdateTimestamp && setLastImageUpdateTimestamp(new Date().getTime())} className={`image image--${type}`} image={getImage(img) as IGatsbyImageData} alt={""}/>;
};

export default Image;

